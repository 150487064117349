<template>
  <div
    class="ma-3"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <h3><strong>{{ $t('under-construction') }}</strong></h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'UnderConstruction',

    methods: {
      //
    },
  }
</script>
